<template>
    <section class="page-wrap py-5">
        <div class="inner-banner">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h1>{{ title }}</h1>
                    </div>
                    <div class="col-12">
                        <Breadcrumb />
                    </div>
                </div>
            </div>        
        </div>
        <div class="container">
            <div v-if="dataStatus" class="row">
                <div class="col-xl-3 col-lg-3 col-md-6 col-6 mt-4 fav-relative" v-for="(item,i) in favouriteLists" :key="i">
                    <ItemList :item="item" />
                    <b-dropdown class="ellipse-remove" right variant="link" toggle-class="text-decoration-none" no-caret>
                        <template #button-content>
                            <span class="icon-ellipse"></span>
                        </template>
                        <b-dropdown-item href="" @click="removedFavouriteList(item.id)">Remove Item</b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>
            <div v-else class="row">
                <NotFound/>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios'
import api from '@/services/api'
import store from "@/store";
import ItemList from '@/components/ItemList'
import Breadcrumb from '@/components/Breadcrumb'
import NotFound from "../../components/NotFound"
import Helper from "@/services/helper"

export default ({
    name:"UserPropertyFavourite",
    components:{
        ItemList,Breadcrumb,NotFound,
    },
    data() {
        return {
            title:"Favourites",
            favouriteLists:[],
            userId:store.getters.user.id,
            dataStatus:true,
        }
    },
    mounted(){
        let app = this;
        app.$root.$emit('wishlist-count',store.getters.userWishlist);
        app.renderProperty();
        document.title = app.title;
    },
    methods:{
        async renderProperty(){
            Helper.showSpinner();
            let app = this;
            let url = '/user/property/wishlist/'+app.userId;
            await axios.get(api.getUrl(url)).then(function (response) {
                if(response.data.success){
                    if(response.data.data.length > 0){
                        store.commit("setUserWishlist",response.data.data.length);
                        app.$root.$emit('wishlist-count',store.getters.userWishlist);
                        app.favouriteLists     = response.data.data;
                    }else{
                        app.dataStatus = false;
                    }
                }
            }).finally(() => {
                Helper.hideSpinner();
            });
        },
        removedFavouriteList(property_id){
            let app = this;
            let url = '/user/property/wishlist/removed';
            axios.post(api.getUrl(url),{user_id:app.userId,property_id:property_id}).then(function (response) {
                if(response.data.success){
                    store.commit("setUserWishlist",response.data.data.length);
                    app.$root.$emit('wishlist-count',store.getters.userWishlist);
                    if(response.data.data.length > 0){
                        app.renderProperty();
                        app.$toast.success(response.data.message,{position: "top-center",timeout: 2000});
                    }else{
                        app.dataStatus = false;
                    }
                }else{
                    app.$toast.error(response.data.message,{position: "top-center",timeout: 2000});
                }
            });
        }
    },
})
</script>
